<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">用户昵称</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            placeholder="请输入用户昵称"
            v-model.trim="searchValue.nickname"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">手机号</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9-]/g,'')"
            placeholder="请输入手机号"
            @keyup.enter.native="getList"
            v-model="searchValue.phoneNumber"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="8">
          <span class="searchText">分销级别</span>
          <el-select class="searchInput" v-model="searchValue.levelId" placeholder="请选择分销级别">
            <el-option label="全部" value=""> </el-option>
            <el-option v-for="item in levelOptions" :key="item.id" :label="item.levelName" :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="query">
        <el-col :span="8" v-if="$store.getters['user/isAllArea']">
          <span class="searchText">活跃地区</span>
          <el-select class="searchInput" placeholder="请选择地区" @change="getList" v-model="searchValue.activeArea">
            <el-option label="全部" :value="undefined"></el-option>
            <el-option
              v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-col>

        <el-col :span="8">
          <span class="searchText">用户ID</span>
          <el-input
            class="searchInput"
            @keyup.enter.native="getList"
            placeholder="请输入用户ID"
            maxlength="19"
            v-model.trim="searchValue.distributionMemberId"
            clearable
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="18">
          <span class="searchText">加入时间</span>
          <el-date-picker
            v-model="date"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>

        <el-button size="mini" style="float: right; margin-right: 10px" type="success" @click="downLoadExcel"
          >导出</el-button
        >
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="info" label="头像">
          <template slot-scope="scope">
            <img class="avatar" :src="scope.row.avatarUrl | thumbSmall" />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="用户ID"> </el-table-column>
        <el-table-column align="center" prop="nickname" label="用户名"> </el-table-column>
        <el-table-column align="center" prop="phoneNumber" label="电话号码"> </el-table-column>

        <el-table-column align="center" prop="distributionLevelName" label="分销级别"> </el-table-column>
        <el-table-column align="center" prop="parentMemberId" label="邀请上级">
          <template slot-scope="{ row }">
            <div v-if="row.parentMemberId && row.parentMemberId !== '0'" class="parentUser">
              <img class="avatar" :src="row.parentAvatarUrl | thumbSmall" />
              <div class="name">{{ row.parentNickname }}</div>
              <div class="name">id: {{ row.parentMemberId }}</div>
            </div>
            <div v-else class="parentUser">
              <div class="name">无</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="累计收益">
          <template slot-scope="scope">
            {{ (scope.row.directPushTotalIncome + scope.row.indirectPushTotalIncome).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="directPushTotalIncome" label="直售佣金"> </el-table-column>

        <el-table-column align="center" label="活跃地区">
          <template slot-scope="scope">
            <div v-if="scope.row.activeArea == 0">无</div>
            <div v-else>{{ scope.row.activeArea | formatArea }}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="indirectPushTotalIncome" label="团队佣金"> </el-table-column>
        <el-table-column align="center" label="加入时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="edit(scope.row)" type="text" size="small"> 编辑</el-button>
            <el-button @click="goto(scope.row, 'distributorBill')" type="text" size="small"> 记录</el-button>
            <el-button @click="goto(scope.row, 'distributorTeam')" type="text" size="small"> 团队</el-button>
            <el-button
              v-if="!scope.row.isBlacklisted"
              @click="pullBlack(scope.row)"
              class="deleteBtnText"
              type="text"
              size="small"
            >
              拉黑
            </el-button>
            <el-button v-else @click="pullBlack(scope.row)" type="text" class="deleteBtnText" size="small">
              取消拉黑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>
    <!--  导出-->

    <el-dialog
      :close-on-click-modal="false"
      title="导出分销员信息"
      :visible.sync="download.show"
      append-to-body
      width="30%"
    >
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 时间</span>
        <el-date-picker
          value-format="timestamp"
          v-model="download.time"
          type="datetimerange"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <div style="margin-top: 20px">
          <span class="searchText">城市</span>
          <el-select class="searchInput" placeholder="请选择城市" @change="getList" v-model="download.areaId">
            <el-option label="全部" :value="undefined"></el-option>
            <el-option
              v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownLoadExcel">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="编辑分销员" :visible.sync="editDialog" width="350px">
      <el-form ref="editForm" :rules="editRules" :model="editForm" label-width="120px">
        <el-form-item label="用户ID" prop="id">
          <el-input disabled v-model="editForm.id"></el-input>
        </el-form-item>
        <el-form-item label="手机">
          <el-input disabled v-model="editForm.phoneNumber"></el-input>
        </el-form-item>

        <el-form-item label="名称" prop="nickname">
          <el-input disabled v-model="editForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="级别" prop="activityDescription">
          <el-select class="searchInput" v-model="editForm.distributionLevelId" placeholder="请选择分销级别">
            <el-option v-for="item in levelOptions" :key="item.id" :label="item.levelName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmEdit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="blockUser.show" width="30%">
      <span class="assist">{{ blockUser.blockContext }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="blockUser.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmBlock">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      editRules: [],
      levelOptions: [],

      date: [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']],
      title: '',
      searchValue: {
        activeArea: undefined,
        nickname: '',
        orderStatus: '',
        memberId: '',
        startDate: '',
        endDate: '',
        distributionMemberId: ''
      },
      blockUser: { url: '', tip: '', show: false, blockContext: '', id: '' },
      editDialog: false,
      list: [],
      download: {
        show: false,
        url: '',
        time: [],
        areaId: ''
      },
      editForm: {
        distributionLevelId: '',
        id: '',
        nickname: '',
        phoneNumber: ''
      }
    }
  },
  created() {
    this.editRules = this.$initRules([
      {
        label: '手机',
        value: 'phoneNumber',
        type: 'input',
        required: true
      }
    ])
    this.getRuleList()
  },
  methods: {
    downLoadExcel() {
      this.download = {
        show: true,
        url: '/boom-center-statistics-service/sysAdmin/exportExl/distributionMember',
        time: []
      }
    },
    confirmDownLoadExcel() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$http
        .get(this.download.url, {
          params: {
            startTime: this.download.time ? this.download.time[0] : undefined,
            endTime: this.download.time ? this.download.time[1] : undefined,
            areaId: this.download.areaId
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    confirmEdit() {
      this.$refs['editForm'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return
        }
        if (!this.editForm.distributionLevelId || !this.editForm.id) {
          this.$message.error(`参数异常，等级ID：${this.editForm.distributionLevelId},用户ID：${this.editForm.id}`)
          return
        }
        this.$api.distribution
          .distribution('putDistributor', {
            distributionLevelId: this.editForm.distributionLevelId,
            distributionMemberId: this.editForm.id
          })
          .then(res => {
            this.editDialog = false
            this.getList()
          })
          .catch(err => {
            this.$message.error(err.msg)
          })
      })
    },
    edit(row) {
      this.editDialog = true
      this.editForm = { ...row }
    },
    getRuleList() {
      this.$api.distribution
        .distribution('distributionRuleList')
        .then(res => {
          this.levelOptions = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 请求后端获取分销员管理
    getList(val) {
      this.loading = true
      if (this.date && this.date.length !== 0) {
        this.searchValue.startDate = this.date[0]
        this.searchValue.endDate = this.date[1]
      } else {
        this.searchValue.startDate = ''
        this.searchValue.endDate = ''
      }
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          page: this.currentPage,
          size: this.pageSize,
          ...this.searchValue
        }
      }

      this.$api.distribution
        .distribution('distributionMemberList', params)
        .then(res => {
          this.list = res.records
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },

    // 点击拉黑按钮
    pullBlack(row) {
      if (row.isBlacklisted) {
        this.blockUser.blockContext = '是否取消拉黑该用户'
        this.blockUser.url = 'cancelBlackDistribution'
        this.blockUser.tip = '取消拉黑成功'
      } else {
        this.blockUser.blockContext = '是否拉黑该用户'
        this.blockUser.url = 'pullBlackDistribution'
        this.blockUser.tip = '拉黑成功'
      }
      this.blockUser.show = true
      this.blockUser.id = row.id
    },
    resetSearch() {
      this.date = [this.$store.getters['global/day7Int'], this.$store.getters['global/todayInt']]
      this.searchValue = {
        activeArea: undefined,
        nickname: '',
        orderStatus: '',
        memberId: '',
        startDate: '',
        endDate: '',
        distributionMemberId: ''
      }
      this.date = []
      this.getList()
    },
    // 确认拉黑用户
    confirmBlock() {
      this.$api.distribution
        .distribution(this.blockUser.url, {
          memberId: this.blockUser.id
        })
        .then(res => {
          this.getList()
          this.blockUser.show = false
          this.$message.success(this.blockUser.tip)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    // 查看用户详情，点击跳转到详情
    goto(row, path) {
      this.$router.push({
        path: '/distribution/' + path,
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.platform-data {
  display: flex;
}

.searchContainer .searchInput {
  width: 180px;
}

.searchContainer .searchInputRange {
  width: 80px;
}
.query {
  margin-top: 20px;
}
.avatar {
  width: 50px;
  height: 50px;
}
.parentUser {
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #999999;
  }
}
</style>
